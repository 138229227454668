import React from "react";
import "./Cube.css";

const Cube = () => {
  return (
    <div className="stage">
      <div className="cube">
        <figure className="front">
          <p>frank meszaros</p>
        </figure>
        <figure className="top" />
        <figure className="bottom" />
        <figure className="left">
          <p>software developer</p>
        </figure>
        <figure className="right">
          <p>noisemaker</p>
        </figure>
        <figure className="back">
          <p>plant enthusiast</p>
        </figure>
      </div>
    </div>
  );
};

export default Cube;
